@import '/src/styles/utilities.scss';

.pageNavigationDesktop {
  display: inline-block;
  background: var(--color-gray-700);
  border-radius: $border-radius-50;
  outline: 1px solid var(--color-gray-700);
}

.pageNavigationDesktop__list {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}

.pageNavigationDesktop__listItem {
  display: flex;
  align-items: center;
}
