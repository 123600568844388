@import '/src/styles/utilities.scss';

.pageNavigation__link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 12rem;
  height: $button-height-small;
  padding: $button-padding-small;
  color: var(--color-text-light);
  border-radius: $border-radius-50;

  span {
    display: inline-flex;
    gap: $space-4;
  }

  &:hover,
  &:focus {
    color: var(--color-gray-300);
  }

  &_isActive {
    color: var(--color-gocomics-blue);
    background-color: var(--color-white);

    &:hover,
    &:focus {
      color: var(--color-gocomics-blue);
    }
  }
}
