@import '/src/styles/utilities.scss';

$navigation-border-radius: $button-height-small * 0.5;

.pageNavigation__mobile {
  position: relative;
  z-index: $zindex-dropdown;
}

.pageNavigation__toggle {
  @include undo-default-button-styles();

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: $button-height-small;
  padding: $space-12;
  color: var(--color-gocomics-blue);
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-100);
  border-radius: $navigation-border-radius;

  &:hover {
    background-color: var(--color-gray-100);
  }

  &:focus-visible {
    outline: 1px solid var(--color-gocomics-blue);
  }

  &_isExpanded {
    border-bottom-width: 1px;
    border-radius: $navigation-border-radius $navigation-border-radius 0 0;
    box-shadow: $elevation-4;
  }
}

.pageNavigation__arrow {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 16px;

  &_isExpanded {
    rotate: 180deg;
  }
}

.pageNavigation__list {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  display: none;
  padding: 0;
  overflow: hidden;
  list-style: none;
  background: var(--color-white);
  border: 1px solid var(--color-gray-100);
  border-top: none;
  border-radius: 0 0 $navigation-border-radius $navigation-border-radius;
  box-shadow: $elevation-4;

  &_isExpanded {
    display: block;
  }
}

.pageNavigation__listItem {
  border-bottom: 1px solid var(--color-gray-100);

  a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: $button-height-small;
    padding: $button-padding-small;
    text-align: center;

    &:hover {
      background: var(--color-gray-100);
    }

    &:focus {
      background: var(--color-gray-200);
      outline: none;

      span {
        color: var(--color-gocomics-blue);
      }
    }
  }

  &:last-child {
    border-bottom: none;
  }
}

.pageNavigation__inactive {
  color: var(--color-gray-700);
}
